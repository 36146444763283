import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Nav from './Nav';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Sidebar from './Sidebar';
import Navbar from './Navbar'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='html-background'>
  <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop/>
        <Nav />
      </BrowserRouter>
    </React.StrictMode>
  </div>
  
);

